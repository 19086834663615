import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const api = "https://api.gbslifesciences.com/";
  return (
    <div>
      {" "}
      <footer className="footer-top-area f-bg pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                <Link href="/">
                  <img
                    src={api + props.Contact.logo}
                    alt="Image"
                    style={{ height: "100px" }}
                  />
                </Link>

                <p>{props.Contact.titile}</p>

                <div className="social-area">
                  <ul>
                    <li>
                      <a href={props.Contact.facebook} target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.Contact.twitter} target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>

                    <li>
                      <a href={props.Contact.instagram} target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-delay="200"
                data-aos-duration="1200"
              >
                <h3>Links</h3>
                <ul>
                  <li>
                    <Link to="/"> Home</Link>
                  </li>
                  <li>
                    <Link to="/about"> About Us</Link>
                  </li>
                  <li>
                    <Link to="/Products"> Products</Link>
                  </li>
                  <li>
                    <Link to="/doctors"> Facility</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="single-widget open-time"
                data-aos="fade-in"
                data-aos-delay="300"
                data-aos-duration="1200"
              >
                <h3>Opening Hours</h3>
                <ul>
                  <li>
                    <span>Mon-Tue:</span>
                    <span className="right">
                      {" "}
                      {props.Contact.fromTime} - {props.Contact.toTime}
                    </span>
                  </li>
                  <li>
                    <span>Wed-Thu:</span>
                    <span className="right">
                      {" "}
                      {props.Contact.fromTime} - {props.Contact.toTime}
                    </span>
                  </li>
                  <li>
                    <span>Fry:</span>
                    <span className="right">
                      {" "}
                      {props.Contact.fromTime} - {props.Contact.toTime}
                    </span>
                  </li>
                  <li>
                    <span>Sun:</span>
                    <span className="right">Closed</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="single-widget contact"
                data-aos="fade-in"
                data-aos-delay="400"
                data-aos-duration="1200"
              >
                <h3>Get In Touch</h3>
                <ul>
                  <li>
                    <a href="tel:+900000000">
                      <i className="bx bx-phone-call"></i>
                      <span>Contact Us:</span>
                      Phone: +91 {props.Contact.contactNumber1}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:hello@GBS.com">
                      <i className="bx bx-envelope"></i>
                      <span>Email:</span>
                      {props.Contact.email1}
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-location-plus"></i>
                    <span>Address:</span>
                    {props.Contact.address}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom-area">
        <div className="container">
          <div className="copy-right">
            <p>
              Copyright &copy; {"2023"} Designed by{" "}
              <a to="/" target="blank">
                GBS
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
