import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const navigate = useNavigate();

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const emaildid = sessionStorage.getItem("eamilotpid");
  const emailotp = sessionStorage.getItem("eamilVerfied");

  const Add = () => {
    const dataArray = {
      emailOtp: form.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("userid", res.data.data._id);
            setform({
              otp: "",
            });
            if (emailotp == "yes") {
              sessionStorage.setItem("token", res.data.token);
              navigate("/signup");
            } else {
              sessionStorage.setItem("token", res.data.token);
              navigate("/");
            }
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />

          <PageBanner
            pageTitle="Sign In"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Sign In"
            imgClass="bg-1"
          />

          <div className="user-area-all-style log-in-area ptb-100">
            <div className="container">
              <div className="section-title">
                <h2>Sign In your account!</h2>
                <p>
                  "Sign-in procedures are a fundamental aspect of online
                  security, helping verify the identity of users and protecting
                  their accounts from unauthorized access. Robust password
                  management and the use of authentication methods contribute to
                  a safer online experience. It's essential for individuals to
                  stay vigilant and adopt best practices to ensure the integrity
                  of their digital identities."
                </p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-form-action">
                    <h2 className="text-center pb-3 pb-3"> Otp </h2>
                    <form
                      onSubmit={(e) => {
                        formsubmit(e);
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="otp"
                              required
                              placeholder="Enter OTP"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.otp}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <button className="default-btn btn-two" type="submit">
                            Enter OTP
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="log-in-img"></div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <Footer Contact={Contact} />
        </>
      )}{" "}
    </>
  );
};

export default SignIn;
