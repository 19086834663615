import React, { useState, useEffect } from "react";
import axios from "axios";

function Filiter() {
  const handleLetterClick = (letter) => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getletterCategory", {
        letter: letter,
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/Products";
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        alert("Failed to fetch products data.");
      });
  };
  return (
    <div>
      <div className="breadcrumbs_area category__list">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_content">
                <ul style={{ cursor: "pointer" }}>
                  {Array.from(Array(26), (_, i) => (
                    <li
                      key={String.fromCharCode(97 + i)}
                      onClick={() =>
                        handleLetterClick(String.fromCharCode(97 + i))
                      }
                    >
                      {String.fromCharCode(97 + i)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filiter;
