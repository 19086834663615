import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const phone = sessionStorage.getItem("mobilenos");

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const navigate = useNavigate();

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const token = sessionStorage.getItem("token");

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: phone,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/userRegistration",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
            });
            navigate("/");
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />

          <PageBanner
            pageTitle="Sign Up"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Sign Up"
            imgClass="bg-2"
          />

          <div className="user-area-all-style sign-up-area ptb-100">
            <div className="container">
              <div className="section-title">
                <h2>Create an account!</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Laudantium quas cumque iste veniam id dolorem deserunt ratione
                  error voluptas rem ullam possimus placeat, ut, odio
                </p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-form-action">
                    <form
                      onSubmit={(e) => {
                        formsubmit(e);
                      }}
                    >
                      <div className="row ">
                        <div className="col-12 mb-3">
                          <label htmlFor="email" className="form-label">
                            Full Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required
                            placeholder="Enter Full Name"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.name}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required
                            placeholder="Enter Email"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.email}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label htmlFor="email" className="form-label">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            required
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            placeholder="Enter Mobile Number"
                            disabled
                            // onChange={(e) => {
                            //   handleChange(e);
                            // }}
                            value={phone}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <div>
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="sign-in-img"></div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
          <Footer Contact={Contact} />
        </>
      )}{" "}
    </>
  );
};

export default SignUp;
