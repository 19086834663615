import React, { useState, useEffect } from "react";
import PageBanner from "./PageBanner";
import Filiter from "./Filiter";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function ProductsDetails() {
  useEffect(() => {
    datas();
    datass();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [Contact, setContact] = useState([]);
  const datas = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const [Products, setProducts] = useState([]);

  const getid = sessionStorage.getItem("Ids");

  const datass = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getproductbyid",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const api = "https://api.gbslifesciences.com/";

  const databyid = (data) => {
    sessionStorage.setItem("Idss", data._id);
    window.location.href = "/productsSubdetails";
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div>
            <Header Contact={Contact} />
            <PageBanner
              pageTitle="products Details"
              homePageUrl="/"
              homePageText="Home"
              activePageText="products Details"
              imgClass="bg-1"
            />{" "}
            <Filiter />
            <div className="blog-details-area ptb-100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="blog-left-sidebar">
                      <div className="widget-area" id="secondary">
                        <div className="widget widget_search">
                          <h3 className="widget-title">Search Now</h3>
                          <div className="post-wrap">
                            <form className="search-form">
                              <label>
                                <span className="screen-reader-text">
                                  Search for:
                                </span>
                                <input
                                  type="search"
                                  className="search-field"
                                  placeholder="Search..."
                                />
                              </label>
                              <button type="submit">
                                <i className="bx bx-search"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                        <div className="widget widget_categories">
                          <h3 className="widget-title">CATEGORY</h3>
                          <div className="post-wrap">
                            <ul>
                              <li>
                                <a href="#">
                                  CATEGORY : <span>ABACAVIR</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="widget widget_categories">
                          <h3 className="widget-title">FILTER BY</h3>
                          <div className="post-wrap">
                            <ul>
                              <li>
                                {Products.map((data, i) => {
                                  return (
                                    <>
                                      <a
                                        title={data.title}
                                        style={{ cursor: "pointer" }}
                                        key={i}
                                        onClick={() => {
                                          databyid(data);
                                        }}
                                      >
                                        {data.name} <span>(10)</span>
                                      </a>
                                    </>
                                  );
                                })}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="blog-details-desc">
                      <div className="row">
                        {Products.map((data, i) => {
                          return (
                            <>
                              <div className="col-lg-6 col-md-6 mt-2">
                                <div
                                  className="single-blog"
                                  style={{ cursor: "pointer" }}
                                >
                                  <a
                                    title={data.title}
                                    style={{ cursor: "pointer" }}
                                    key={i}
                                    onClick={() => {
                                      databyid(data);
                                    }}
                                  >
                                    {data.image == "" || data.image == null ? (
                                      <>
                                        <img
                                          src="img/product.png"
                                          alt="Image"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <img
                                          src={api + data.image}
                                          alt="Image"
                                        />{" "}
                                      </>
                                    )}
                                  </a>
                                  <div className="blog-content">
                                    <a
                                      title={data.name}
                                      style={{ cursor: "pointer" }}
                                      key={i}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                    >
                                      <h3>{data.name}</h3>
                                    </a>
                                    <div class="product_content grid_content">
                                      <div class="product_price_rating">
                                        <div>
                                          <span class="label">CAT No</span>:
                                          {data.catNo}
                                        </div>
                                        <div>
                                          <span class="label">CAS No</span>:{" "}
                                          <span class="clscas">
                                            {" "}
                                            {data.casNo}
                                          </span>
                                        </div>
                                        <div>
                                          <span class="label">Mol.F. :</span>
                                          <span
                                            class="value clsmf"
                                            id="gtin-12554"
                                          >
                                            {data.molF}
                                          </span>
                                        </div>
                                        <div>
                                          <span class="label">Mol.Wt. :</span>
                                          <span
                                            class="value clsmw"
                                            id="gtin-12554"
                                          >
                                            {data.molWt}
                                          </span>
                                        </div>
                                        <div>
                                          <span class="label">
                                            Inv. Status :
                                          </span>
                                          {data.invStatus}
                                        </div>
                                        <div>
                                          <span class="label">Rel. CAS No</span>
                                          :{" "}
                                          <span class="clsrelcas">
                                            {data.resCasno}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <a
                                      title={data.name}
                                      style={{ cursor: "pointer" }}
                                      key={i}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                    >
                                      Read More <i className="bx bx-plus"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer Contact={Contact} />
          </div>
        </>
      )}
    </div>
  );
}

export default ProductsDetails;
