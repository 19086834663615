import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";

function Contact() {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  return (
    <div>
      {" "}
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />
          <PageBanner
            pageTitle="Contact"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Contact"
            imgClass="bg-1"
          />

          <div className="contact-info-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 p-0  mt-4">
                  <div className="single-contact-info">
                    <i className="bx bx-location-plus"></i>
                    <h3>Location</h3>
                    <p>{Contact.address}</p>
                    <span>Email: {Contact.email1}</span>
                    <span>Mail: {Contact.email2}</span>
                    <span>Tel: +91 {Contact.contactNumber1}</span>
                    <span>Phone: +91 {Contact.contactNumber1}</span>
                  </div>
                </div>

                <div className="col-lg-8 p-0">
                  <div className="single-contact-map mt-4">
                    <iframe
                      src={Contact.maps}
                      frameBorder={0}
                      scrolling="no"
                      style={{ height: "460px", width: "99%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="appointment-area-seven ptb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="appointment-here-form">
                    <span className="top-title">Contact Us</span>
                    <h2>We Are Here For You</h2>

                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="Name"
                              placeholder="Enter Your Name"
                            />
                            <i className="flaticon-account"></i>
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="Phone"
                              placeholder="Enter Your Phone"
                            />
                            <i className="flaticon-smartphone"></i>
                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="Email"
                              placeholder="Enter Your Email"
                            />
                            <i className="flaticon-email"></i>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              className="form-control"
                              id="Message"
                              cols="30"
                              rows="5"
                              placeholder="Your Message"
                            ></textarea>
                            <i className="flaticon-edit"></i>
                          </div>
                        </div>

                        <div className="col-12">
                          <button type="submit" className="default-btn">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="appointment-bg"></div>
                </div>
              </div>
            </div>
          </div>
          <Footer Contact={Contact} />
        </>
      )}{" "}
    </div>
  );
}

export default Contact;
