import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function ProductsSubDetails() {
  useEffect(() => {
    datas();
  }, []);

  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const [showOtpForm, setShowOtpForm] = useState(false);

  const [form, setform] = useState([]);

  const [tokens, settokens] = useState(false);

  const [tokens1, settokens1] = useState(false);

  useEffect(() => {
    datasss();
  }, []);

  const datasss = () => {
    const tokens = sessionStorage.getItem("token");

    if (tokens != null) {
      settokens(true);
      settokens1(true);
    }
  };

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      phone: form.phone,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/logincheck",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("eamilotpid", res.data.data._id);
            sessionStorage.setItem("eamilVerfied", res.data.data.new_user);
            sessionStorage.setItem("mobilenos", res.data.data.phone);
            setform({
              phone: "",
            });
            setShowOtpForm(true);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form2, setform2] = useState([]);

  const formsubmit2 = (e) => {
    e.preventDefault();
    Add2();
  };

  const handleChange2 = (e) => {
    let myUser = { ...form2 };
    myUser[e.target.name] = e.target.value;
    setform2(myUser);
  };

  const emaildid = sessionStorage.getItem("eamilotpid");

  const Add2 = () => {
    const dataArray = {
      emailOtp: form2.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userid", res.data.data._id);
            settokens(true);
            settokens1(true);
            GetProfile();
            setform({
              otp: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const productsvalue = localStorage.getItem("cart");

  const productsvalueprice = localStorage.getItem("totalprice");

  const products = JSON.parse(productsvalue);

  console.log(products.id);

  const [form3, setform3] = useState([]);

  const formsubmit3 = (e) => {
    e.preventDefault();
    AddBooking();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const AddBooking = () => {
    const userid = sessionStorage.getItem("userid");

    const dataArray = {
      userId: userid,
      fullName: form3.fullName,
      email: form3.email,
      pincode: form3.pincode,
      address: form3.address,
      phone: form3.phone,
      state: form3.state,
      subTotal: productsvalueprice,
      totalAmount: productsvalueprice,
      services: products,
      slotDate: form3.date,
      slotTime: form3.time,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/orderitem/addorderitem",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);

            const totalss = parseInt(productsvalueprice) * 100;

            const options = {
              key: "rzp_test_HJG5Rtuy8Xh2NB",
              amount: totalss,
              currency: "INR",
              name: "GBS",
              description: "GBS Transaction",
              image:
                "https://ssairconditioningsolutions.com/wp-content/uploads/2023/05/Logo-4-3-e1685259033945-281x127.png",

              handler: function (response) {
                var token = sessionStorage.getItem("token");

                const dataArrays = {
                  transactionId: response.razorpay_payment_id,
                  orderId: res.data.booking._id,
                };

                axios
                  .post(
                    "https://api.gbslifesciences.com/v1/gbsapi/web/payment/addpayment",
                    dataArrays,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                  .then(
                    (res) => {
                      if (res.status === 200) {
                        toast(res.data.message);
                        navigate("/acmantanceservice");
                        localStorage.clear();
                        rzp1.close();
                      }
                    },
                    (error) => {
                      if (error.response && error.response.status === 400) {
                        toast(error.response.data.message);
                        rzp1.close();
                      }
                    }
                  );
              },
              prefill: {
                name: sessionStorage.getItem("name"),
                email: sessionStorage.getItem("email"),
                contact: sessionStorage.getItem("phone"),
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#015A65",
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/getprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform3(res.data.user);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div>
            <Header Contact={Contact} />
            <PageBanner
              pageTitle="products Details"
              homePageUrl="/"
              homePageText="Home"
              activePageText="products Details"
              imgClass="bg-1"
            />{" "}
          </div>
          <div style={{ marginBottom: "100px" }}>
            <div className="doctors-details-area pt-3 ">
              <div className="container">
                <h4 className="text-center text-danger mt-5 mb-5">
                  Payment Managment{" "}
                </h4>
                <div>
                  {" "}
                  <div className="doctors-detailss">
                    <div className="doctors-history">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr className="text-center">
                              <th>Product Name</th>
                              <th>Product Quantity</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.map((data, index) => (
                              <tr className="text-center" key={index}>
                                <td>
                                  <h5 className="text-primary">{data.name}</h5>
                                </td>
                                <td>
                                  {" "}
                                  <h5 className="text-primary">
                                    {data.quantity}
                                  </h5>
                                </td>
                                <td>
                                  <h5 className="text-primary">{data.price}</h5>
                                </td>
                              </tr>
                            ))}
                          </tbody>

                          <tfoot>
                            <tr className="tm-checkout-total text-center">
                              <td></td>
                              <td
                                colSpan={1}
                                style={{ color: "#000 ", fontSize: "20px" }}
                              >
                                Total :
                              </td>
                              <td style={{ color: "#000 ", fontSize: "20px" }}>
                                {productsvalueprice}/-
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {tokens === false ? (
                  <>
                    <div className="tm-checkout-coupon mt-5 bg-white">
                      <a
                        href="#Verify-Your-Mobile-Number"
                        data-toggle="collapse"
                      >
                        <span>Verify Your Mobile Number</span>
                      </a>
                      <div
                        id="Verify-Your-Mobile-Number"
                        className="collapse show"
                      >
                        <div className="doctors-detailss">
                          <div className="doctors-history">
                            {" "}
                            {!showOtpForm && (
                              <form
                                onSubmit={(e) => {
                                  formsubmit(e);
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3 col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="phone"
                                      required
                                      maxlength="10"
                                      minLength="10"
                                      pattern="[0-9]{10}"
                                      placeholder="Enter Mobile Number"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.phone}
                                    />
                                  </div>

                                  <div className="col-3 col-sm-6 mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Send <b />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                            {showOtpForm && (
                              <form
                                onSubmit={(e) => {
                                  formsubmit2(e);
                                }}
                              >
                                {" "}
                                <div className="row">
                                  <div className="col-md-3 col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otp"
                                      required
                                      maxlength="6"
                                      minLength="6"
                                      pattern="[0-9]{6}"
                                      placeholder="Enter otp Number"
                                      onChange={(e) => {
                                        handleChange2(e);
                                      }}
                                      value={form2.otp}
                                    />
                                  </div>
                                  <div className="col-md-3 col-sm-6 mt-3">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Submit <b />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {tokens === true ? (
                  <>
                    <div className="tm-checkout-coupon mt-5 mb-5">
                      <a
                        href="#Verify-Your-Mobile-signin"
                        data-toggle="collapse"
                        className="bg-white"
                      >
                        <span>Verify Your Details</span>
                      </a>

                      <div
                        id="Verify-Your-Mobile-signin"
                        className="collapse  show"
                      >
                        <form
                          className="tm-form tm-checkout-form mt-5 "
                          onSubmit={(e) => {
                            formsubmit3(e);
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="doctors-detailss">
                                <div className="doctors-history">
                                  <h4 className="small-title">
                                    BILLING INFORMATION
                                  </h4>
                                  <div className="tm-checkout-billingform">
                                    <div className="tm-form-inner">
                                      <div className=" mb-3 ">
                                        <label htmlFor="billingform-firstname">
                                          Full Name*
                                        </label>
                                        <input
                                          id="billingform-firstname"
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          required
                                          placeholder="Enter Full Name"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.name}
                                        />
                                      </div>

                                      <div className=" mb-3">
                                        <label htmlFor="billingform-email">
                                          Email address
                                        </label>
                                        <input
                                          type="email"
                                          id="billingform-email"
                                          className="form-control"
                                          name="email"
                                          required
                                          placeholder="Enter Email"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.email}
                                        />
                                      </div>
                                      <div className=" mb-3">
                                        <label htmlFor="billingform-phone">
                                          Phone Number
                                        </label>
                                        <input
                                          type="text"
                                          id="billingform-phone"
                                          name="phone"
                                          className="form-control"
                                          required
                                          maxlength="10"
                                          minLength="10"
                                          pattern="[0-9]{10}"
                                          placeholder="Enter Phone Number"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.phone}
                                        />
                                      </div>

                                      <div className=" mb-3">
                                        <label htmlFor="billingform-phone">
                                          Date
                                        </label>
                                        <input
                                          type="date"
                                          id="billingform-phone"
                                          name="date"
                                          className="form-control"
                                          required
                                          placeholder="Enter Date"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.date}
                                        />
                                      </div>

                                      <div className=" mb-3">
                                        <label htmlFor="billingform-phone">
                                          Time
                                        </label>
                                        <input
                                          type="time"
                                          id="billingform-phone"
                                          name="time"
                                          className="form-control"
                                          required
                                          placeholder="Enter Time"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.time}
                                        />
                                      </div>

                                      <div className=" mb-3">
                                        <label htmlFor="billingform-address">
                                          Address
                                        </label>
                                        <input
                                          type="text"
                                          id="billingform-address"
                                          placeholder="Apartment, Street Address ,Location"
                                          name="address"
                                          className="form-control"
                                          required
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.address}
                                        />
                                      </div>
                                      <div className=" mb-3">
                                        <label htmlFor="billingform-streetaddress">
                                          State
                                        </label>
                                        <input
                                          type="text"
                                          id="billingform-streetaddress"
                                          placeholder="Enter State"
                                          className="form-control"
                                          name="state"
                                          required
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.state}
                                        />
                                      </div>
                                      <div className=" mb-5">
                                        <label htmlFor="billingform-zipcode">
                                          Zip / Postcode
                                        </label>
                                        <input
                                          type="text"
                                          id="billingform-zipcode"
                                          placeholder="Enter  Zip / Postcode"
                                          name="pincode"
                                          className="form-control"
                                          required
                                          maxlength="6"
                                          minLength="6"
                                          pattern="[0-9]{6}"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.pincode}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="doctors-detailss">
                                <div className="doctors-history">
                                  <div className="tm-checkout-orderinfo bg-grey">
                                    <div className="tm-checkout-payment">
                                      <h4>Payment Method</h4>
                                    </div>
                                    <div className="tm-checkout-submit">
                                      <p>
                                        Your personal data will be used to
                                        process your order, support your
                                        experience throughout this website, and
                                        for other purposes described in our
                                        privacy policy.
                                      </p>
                                      <div className="tm-form-inner">
                                        <div className=" mb-3">
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                          >
                                            Place Order <b />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <ToastContainer />
          <Footer Contact={Contact} />
        </>
      )}
    </div>
  );
}

export default ProductsSubDetails;
