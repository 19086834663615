import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import Filiter from "./Filiter";

function ProductsSubDetails() {
  useEffect(() => {
    datas();
    datass();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const [Product, setProduct] = useState([]);

  const [Products, setProducts] = useState([]);

  const getid = sessionStorage.getItem("Idss");

  const [cart, setCart] = useState([]);

  const datass = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getsingleproductbyid",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProduct(res.data.product[0]);
            setProducts(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const api = "https://api.gbslifesciences.com/";

  const handleCountUp = (
    array,
    setArray,
    index,
    quantity,
    type,
    serviceType
  ) => {
    addToCart(array[index], type, serviceType);
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (item, type, serviceType) => {
    if (cart.length > 0) {
      const check = getObjectById(cart, item._id, serviceType);

      if (check !== null) {
        const updatedCart = [...cart];
        console.log("auan", updatedCart[check].quantity);

        updatedCart[check] = {
          ...updatedCart[check],
          quantity:
            type === "add"
              ? updatedCart[check].quantity + 1
              : updatedCart[check].quantity - 1,
        };

        if (updatedCart[check].quantity == 0) {
          updatedCart.splice(check, 1);
        }
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      } else {
        const newCartItem = {
          id: item._id,
          name: item.name,
          price: parseFloat(item.amount),
          quantity: 1,
          type: serviceType,
        };
        setCart([...cart, newCartItem]);
        localStorage.setItem("cart", JSON.stringify([...cart, newCartItem]));
      }
    } else {
      const newCartItem = {
        id: item._id,
        name: item.name,
        price: parseFloat(item.amount),
        quantity: 1,
        type: serviceType,
      };
      setCart([newCartItem]);
      localStorage.setItem("cart", JSON.stringify([newCartItem]));
    }
  };

  const calculateTotalPrice = () => {
    if (cart.length === 0) {
      return 0;
    }

    return cart.reduce((total, item) => {
      return total + parseFloat(item.price) * item.quantity;
    }, 0);
  };

  const totalCartPrice = calculateTotalPrice();

  localStorage.setItem("totalprice", totalCartPrice);

  function getObjectById(array, targetId, serviceType) {
    var i = 0;
    for (const obj of array) {
      if (obj.id === targetId && serviceType === obj.type) {
        return i;
      }
      i++;
    }
    return null;
  }

  const databyid = (data) => {
    sessionStorage.setItem("Idsss", data);
    window.location.href = "/CheckOut";
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div>
            <Header Contact={Contact} />
            <PageBanner
              pageTitle="products Details"
              homePageUrl="/"
              homePageText="Home"
              activePageText="products Details"
              imgClass="bg-1"
            />{" "}
          </div>
          <Filiter />

          <div className="doctors-details-area pt-1">
            <div className="container">
              {/* <div className="row pb-5">
                <div className="col-lg-12">

                <h2 className="mt-3 mb-3 text-center">{Product.name}</h2>
                  <div className="doctors-detailss">
                    <div className="doctors-history ">

                      <Row>
                        <Col md={4}>
                          <img
                            src={api + Product.image}
                            alt="Image"
                            style={{ height: "200px" }}
                          />
                        </Col>

                        <Col md={4}>
                          
                          <h4 className="mt-5 text-danger">
                            Price : ₹ {Product.amount} /-
                          </h4>
                        </Col>

                        <Col md={12}>
                          <p className="mt-5">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Repellat, totam! Dicta rerum deserunt itaque.
                            Incidunt in quo architecto eveniet rem facere,
                            necessitatibus, dolorem voluptas deleniti iure fuga
                            magni velit molestiae ipsum dolor sit amet
                            consectetur adipisicing elit. Repellat, totam
                            adipisicing.
                          </p>
                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>SZ CAT No</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    {Product.catNo}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>CAS No</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    {Product.casNo}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>Mol.F.</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    {Product.molF}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>Mol.Wt.</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    {Product.molWt}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>Inv. Status</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    <a href="#" target="_blank">
                                      {Product.invStatus}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>Rel. CAS No</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>{Product.resCasno}</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row borders">
                            <div className="col-lg-3 pl-0">
                              <div className="left-title">
                                <h3>Shipping Condition</h3>
                              </div>
                            </div>

                            <div className="col-lg-9">
                              <div className="right-title">
                                <ul>
                                  <li>
                                    <i className="bx bxs-hand-right"></i>
                                    {Product.shippingCondition}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            {Products.map((data, index) => (
                              <div key={index} className="mb-3">
                                {getObjectById(cart, data._id, "products") ===
                                  null ||
                                cart[getObjectById(cart, data._id, "products")]
                                  .quantity === 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleCountUp(
                                        Products,
                                        setProducts,
                                        index,
                                        data.quantity,
                                        "add",
                                        "products"
                                      )
                                    }
                                  >
                                    Add +
                                  </button>
                                ) : (
                                  <div className="row">
                                    <div className="col">
                                      <button
                                        type="button"
                                        className="btn btn-danger m-1"
                                        onClick={() =>
                                          handleCountUp(
                                            Products,
                                            setProducts,
                                            index,
                                            data.quantity,
                                            "minus",
                                            "products"
                                          )
                                        }
                                      >
                                        -
                                      </button>
                                    </div>{" "}
                                    <div className="col">
                                      <span className="m-2">
                                        {cart.length > 0
                                          ? cart[
                                              getObjectById(
                                                cart,
                                                data._id,
                                                "products"
                                              )
                                            ].quantity
                                          : 0}
                                      </span>
                                    </div>{" "}
                                    <div className="col">
                                      <button
                                        type="button"
                                        className="btn btn-primary  m-1"
                                        onClick={() =>
                                          handleCountUp(
                                            Products,
                                            setProducts,
                                            index,
                                            data.quantity,
                                            "add",
                                            "products"
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <hr className="mt-3" />
                              </div>
                            ))}{" "}
                            <div className="text-right mt-4">
                              {totalCartPrice === 0 ? (
                                ""
                              ) : (
                                <>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      databyid();
                                    }}
                                  >
                                    <i className="fa fa-shopping-cart mr-2" />
                                    Checkout Total: ₹{totalCartPrice}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div> */}
              <div
                className="row"
                itemScope=""
                itemType="http://schema.org/Product"
                data-productid={12554}
              >
                <div className="col-lg-12 col-md-12">
                  <h1 className="product-detail-title text-center pt-3 pb-3">
                    {Product.name}
                  </h1>
                </div>
                <div className="col-lg-6 col-md-6">
                  <link
                    href="/Content/magnific-popup/magnific-popup.css"
                    rel="stylesheet"
                  />
                  <div className="product-details-tab">
                    <div className="zoomWrapper single-zoom">
                      <a className="mt-5 mx-5">
                        <img
                          itemProp="photo"
                          className="kop"
                          src={api + Product.image}
                          data-zoom-image={api + Product.image}
                          alt="Picture of Abacavir Sulfate"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="product_d_right">
                    <div className="product_d_table" itemProp="description">
                      <table>
                        <tbody>
                          <tr itemProp="catnumber">
                            <td className="first_child">SZ CAT No</td>
                            <td>{Product.catNo}</td>
                          </tr>
                          <tr itemProp="casnumber">
                            <td className="first_child">CAS No</td>
                            <td> {Product.casNo}</td>
                          </tr>
                          <tr itemProp="molecularformula">
                            <td className="first_child">Mol.F.</td>
                            <td> {Product.molF}</td>
                          </tr>
                          <tr itemProp="molecularweight">
                            <td className="first_child">Mol.Wt.</td>
                            <td>{Product.molWt}</td>
                          </tr>
                          <tr itemProp="productstatus">
                            <td
                              className="first_child"
                              style={{ color: "#009899" }}
                            >
                              {Product.invStatus}
                            </td>
                            <td
                              style={{ color: "#009899", fontWeight: "bold" }}
                            >
                              Custom Synthesis
                            </td>
                          </tr>
                          <tr itemProp="relatedcasnumber">
                            <td className="first_child">Rel. CAS No</td>
                            <td>{Product.resCasno}</td>
                          </tr>
                          <tr itemProp="shippingconditions">
                            <td>Shipping Condition</td>
                            <td>{Product.shippingCondition}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Col md={12}>
                    <div>
                      {Products.map((data, index) => (
                        <div key={index} className="mb-3">
                          {getObjectById(cart, data._id, "products") === null ||
                          cart[getObjectById(cart, data._id, "products")]
                            .quantity === 0 ? (
                            <button
                              type="button"
                              className="btn"
                              style={{background:"#009899" ,color:"white"}}
                              onClick={() =>
                                handleCountUp(
                                  Products,
                                  setProducts,
                                  index,
                                  data.quantity,
                                  "add",
                                  "products"
                                )
                              }
                            >
                              ADD TO RFQ LIST +
                            </button>
                          ) : (
                            <div className="row">
                              <div className="col-1">
                                <button
                                  type="button"
                                  className="btn btn-danger m-1"
                                  onClick={() =>
                                    handleCountUp(
                                      Products,
                                      setProducts,
                                      index,
                                      data.quantity,
                                      "minus",
                                      "products"
                                    )
                                  }
                                >
                                  -
                                </button>
                              </div>{" "}
                              <div className="col-1">
                                <h5 className="m-2 ">
                                  {cart.length > 0
                                    ? cart[
                                        getObjectById(
                                          cart,
                                          data._id,
                                          "products"
                                        )
                                      ].quantity
                                    : 0}
                                </h5>
                              </div>{" "}
                              <div className="col-1">
                                <button
                                  type="button"
                                  className="btn btn-primary  m-1"
                                  onClick={() =>
                                    handleCountUp(
                                      Products,
                                      setProducts,
                                      index,
                                      data.quantity,
                                      "add",
                                      "products"
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          )}
                          <hr className="mt-3" />
                        </div>
                      ))}{" "}
                      <div className="text-right mt-4" style={{float:"right"}}>
                        {totalCartPrice === 0 ? (
                          ""
                        ) : (
                          <>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                databyid();
                              }}
                            >
                              <i className="fa fa-shopping-cart mr-2" />
                              Checkout Total: ₹{totalCartPrice}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
              <hr></hr>
              <div className="row pb-5 mb-5 mt-5">
                <div className="">
                  <div className="doctors-history ">
                    <Tabs
                      defaultActiveKey="productoverview"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="productoverview" title="Product Overview">
                        {" "}
                        <div
                          className="mt-5"
                          dangerouslySetInnerHTML={{
                            __html: Product.overview,
                          }}
                        ></div>
                      </Tab>

                      <Tab eventKey="technicaldata" title="Technical Data">
                        {" "}
                        <div style={{ width: "100%" }}>
                          <div
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: Product.reference,
                            }}
                          ></div>
                        </div>
                      </Tab>

                      <Tab eventKey="reference" title="Reference">
                        {" "}
                        <div style={{ width: "100%" }}>
                          <div
                            className="mt-5"
                            dangerouslySetInnerHTML={{
                              __html: Product.technicalData,
                            }}
                          ></div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <Footer Contact={Contact} />
        </>
      )}
    </div>
  );
}

export default ProductsSubDetails;
