import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";

function Products() {
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [productscat, setProductscat] = useState([]);
  const [defaultLetter, setDefaultLetter] = useState("a");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {})
      .then((res) => {
        if (res.status === 200) {
          setContact(res.data.contactus[0]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact data:", error);
        alert("Failed to fetch contact data.");
      });
  };

  useEffect(() => {
    redirectPage(defaultLetter);
  }, [defaultLetter]);

  const redirectPage = (letter) => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getletterCategory", {
        letter: letter,
      })
      .then((res) => {
        if (res.status === 200) {
          setProductscat(res.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        alert("Failed to fetch products data.");
      });
  };

  const handleLetterClick = (letter) => {
    setDefaultLetter(letter);
  };

  const databyid = (data) => {
    sessionStorage.setItem("Ids", data._id);
    window.location.href = "/productsdetails";
  };

  return (
    <div>
      {isLoading ? (
        <div className="centers">
          <span className="loader"></span>
        </div>
      ) : (
        <>
          <Header Contact={contact} />
          <PageBanner
            pageTitle="Products"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Products"
            imgClass="bg-1"
          />
          <div className="breadcrumbs_area category__list">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb_content">
                    <ul style={{ cursor: "pointer" }}>
                      {Array.from(Array(26), (_, i) => (
                        <li
                          key={String.fromCharCode(97 + i)}
                          className={`letter letter-active ${
                            defaultLetter === String.fromCharCode(97 + i)
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleLetterClick(String.fromCharCode(97 + i))
                          }
                          style={{
                            color:
                              defaultLetter === String.fromCharCode(97 + i)
                                ? "red"
                                : "white",
                          }}
                        >
                          {String.fromCharCode(97 + i)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "#F5F5F5",
              paddingBottom: "250px",
              paddingTop: "30px",
            }}
          >
            <div className="container">
              <div class="text-center">
                <h4 style={{ color: "#0cb8b6" }}>
                  Total API : {productscat.length}
                </h4>
              </div>
              <div className="row pt-3 pb-5 m-3">
                {productscat.map((data) => (
                  <div key={data._id} className="col-lg-3 col-sm-3">
                    <div
                      className="single-symptoms"
                      title={data.title}
                      onClick={() => databyid(data)}
                    >
                      <h3>
                        <i className="flaticon-checked"></i> {data.title}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer Contact={contact} />
        </>
      )}
    </div>
  );
}

export default Products;
