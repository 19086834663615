import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

const SignIn = () => {
  const phone = sessionStorage.getItem("mobilenos");

  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const token = sessionStorage.getItem("token");

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: phone,
    };

    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/auth/userRegistration",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />

          <PageBanner
            pageTitle="My Account"
            homePageUrl="/"
            homePageText="Home"
            activePageText="My Account"
            imgClass="bg-1"
          />

          <div className="user-area-all-style log-in-area ptb-100">
            <div className="container">
              <div className="section-title">
                <h2>My Account!</h2>
              </div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Edit Profile</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">My Profile</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Third">Pending Bookings</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Forth">Completed Bookings</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Fifth">Payments</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="contact-form-action">
                              <form
                                onSubmit={(e) => {
                                  formsubmit(e);
                                }}
                              >
                                <div className="row ">
                                  <div className="col-12 mb-3">
                                    <label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      Full Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      required
                                      placeholder="Enter Full Name"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.name}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      Email{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      required
                                      placeholder="Enter Email"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.email}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      Mobile Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="phone"
                                      required
                                      maxlength="10"
                                      minLength="10"
                                      pattern="[0-9]{10}"
                                      placeholder="Enter Mobile Number"
                                      disabled
                                      value={phone}
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div>
                                      <button
                                        className="btn btn-primary w-100"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="sign-in-img"></div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">My Profile</Tab.Pane>
                      <Tab.Pane eventKey="Third">Pending tab content</Tab.Pane>
                      <Tab.Pane eventKey="Forth">
                        Completed tab content
                      </Tab.Pane>
                      <Tab.Pane eventKey="Fifth">Payments tab content</Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <ToastContainer />
          <Footer Contact={Contact} />
        </>
      )}{" "}
    </>
  );
};

export default SignIn;
