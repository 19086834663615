import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";

function Doctors() {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const [Professionals, setProfessionals] = useState([]);

  const api = "https://api.gbslifesciences.com/";

  const datas = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProfessionals(res.data.faculties);
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />
          <PageBanner
            pageTitle="Facility"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Facility"
            imgClass="bg-2"
          />
          <div className="doctors-area-two ptb-100">
            <div className="container">
              <div className="section-title">
                <span className="top-title">Facility</span>
                <h2>MeetFacility</h2>
                <p>
                  Our lab technicians are highly skilled professionals dedicated
                  to providing accurate and timely laboratory results.
                </p>
              </div>

              <div className="row justify-content-center">
                {Professionals.map((data, i) => {
                  return (
                    <>
                      <div className="col-lg-4 col-sm-6" key={i}>
                        <div className="single-doctors-two">
                          <div className="doctor-img">
                            <img src={api + data.image} alt="Image" />

                            <div className="doctors-content">
                              <h3>{data.name}</h3>
                              <span>{data.designition}</span>

                              <ul className="doctors-link">
                                <li>
                                  <a href={data.facebook} target="_blank">
                                    <i className="bx bxl-facebook"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={data.twitter} target="_blank">
                                    <i className="bx bxl-twitter"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href={data.instagram} target="_blank">
                                    <i className="bx bxl-instagram"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <Footer Contact={Contact} />{" "}
        </>
      )}
    </div>
  );
}

export default Doctors;
