import React, { useState, useEffect } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Slider from "react-slick";

function Main() {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Sliders, setSliders] = useState([]);
  const [Aboutus, setAboutus] = useState([]);
  const [Aboutus1, setAboutus1] = useState([]);
  const [Contact, setContact] = useState([]);
  const [HomeContant, setHomeContant] = useState([]);
  const [Testimonials, setTestimonials] = useState([]);
  const [Professionals, setProfessionals] = useState([]);
  const [Clients, setClients] = useState([]);

  const api = "https://api.gbslifesciences.com/";

  const datas = () => {
    axios
      .post(
        "https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setSliders(res.data.sliders);
            setAboutus(res.data.aboutus[0]);
            setAboutus1(res.data.aboutus[0].points2);
            setHomeContant(res.data.homecontents);
            setProfessionals(res.data.faculties);
            setTestimonials(res.data.testimonials);
            setClients(res.data.clients);
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div>
      <>
        {isLoading == true ? (
          <>
            <div class="centers">
              <span class="loader"></span>
            </div>
          </>
        ) : (
          <>
            <Header Contact={Contact} />
            <div className="hero-slider-area hero-slider-area-seven">
              <Swiper
                spaceBetween={30}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation, Pagination]}
                className="hero-slider-slide"
              >
                {" "}
                {Sliders.map((data, i) => {
                  return (
                    <>
                      <SwiperSlide key={i}>
                        <div
                          className="slider-item"
                          style={{
                            backgroundImage: `url(${api + data.image})`,
                          }}
                        >
                          <div className="d-table">
                            <div className="d-table-cell">
                              <div className="container">
                                <div className="slider-text one">
                                  <span className="top-title">
                                    Welcome To GBS
                                  </span>
                                  <h1>{data.title}</h1>
                                  <p>{data.description}</p>

                                  <div className="slider-btn">
                                    <a href={data.link} className="default-btn">
                                      Book An Appointment
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>

            <div className="second-facility-area seven pt-100 pb-70">
              <div className="container">
                <div className="row justify-content-center">
                  {HomeContant.map((data, i) => {
                    return (
                      <>
                        <div className="col-lg-4 col-sm-6 p-1" key={i}>
                          <div className="second-facility-item">
                            <img src={api + data.image} alt="Image" />
                            <h3>{data.title}</h3>
                            <p>{data.description}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="about-area-seven bg-color ptb-100">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="about-content">
                      <h2>{Aboutus.titile2}</h2>
                      <p>{Aboutus.description2}</p>

                      <ul className="m-0">
                        {Aboutus1.map((data, i) => {
                          return (
                            <>
                              <li key={i}>
                                <i className="bx bx-check"></i>
                                {data.points2}
                              </li>
                            </>
                          );
                        })}
                      </ul>

                      <div className="about-btn">
                        <Link to="/about" className="default-btn">
                          About Us
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="about-img">
                      <img src={api + Aboutus.image2} alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="doctors-area-two seven pt-100 pb-70">
              <div className="container">
                <div className="section-title">
                  <span className="top-title">Facility</span>
                  <h2>Facility</h2>
                  <p>
                    Our lab technicians are highly skilled professionals
                    dedicated to providing accurate and timely laboratory
                    results.
                  </p>
                </div>

                <div className="row justify-content-center">
                  {Professionals.map((data, i) => {
                    return (
                      <>
                        <div className="col-lg-4 col-sm-6" key={i}>
                          <div className="single-doctors-two">
                            <div className="doctor-img">
                              <img src={api + data.image} alt="Image" />

                              <div className="doctors-content">
                                <h3>{data.name}</h3>
                                <span>{data.designition}</span>

                                <ul className="doctors-link">
                                  <li>
                                    <a href={data.facebook} target="_blank">
                                      <i className="bx bxl-facebook"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href={data.twitter} target="_blank">
                                      <i className="bx bxl-twitter"></i>
                                    </a>
                                  </li>

                                  <li>
                                    <a href={data.instagram} target="_blank">
                                      <i className="bx bxl-instagram"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="client-area c-bg pt-100 pb-70">
              <div className="container">
                <div className="section-title">
                  <span className="top-title">Testimonials</span>
                  <h2>What Our Client’s Say</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                    facilis vel consequatur tempora atque blanditiis
                    exercitationem incidunt, alias corporis quam assumenda
                    dicta, temporibus.
                  </p>
                </div>

                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 6500,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                  }}
                  className="client-slide"
                >
                  {Testimonials.map((data, i) => {
                    return (
                      <>
                        <SwiperSlide key={i}>
                          <div className="single-client">
                            <img src={api + data.image} alt="img" />

                            <p>{data.description}</p>

                            <ul>
                              <li>
                                <i className="bx bxs-star"></i>
                              </li>
                              <li>
                                <i className="bx bxs-star"></i>
                              </li>
                              <li>
                                <i className="bx bxs-star"></i>
                              </li>
                              <li>
                                <i className="bx bxs-star"></i>
                              </li>
                              <li>
                                <i className="bx bxs-star"></i>
                              </li>
                            </ul>

                            <h3>{data.name}</h3>
                            <span>{data.designition}</span>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            </div>

            <div className="counter-area pt-100 pb-70">
              <div className="container ">
                <div className="row justify-content-center">
                  <div className="section-title">
                    <span className="top-title"> Our Client’s</span>
                    <h2>Our Client’s</h2>
                  </div>

                  <Slider {...settings}>
                    {Clients.map((data, i) => {
                      return (
                        <>
                          <div className="col-lg-3 text-center" key={i}>
                            <img
                              src={api + data.image}
                              style={{ height: "80px", width: "120px" }}
                            ></img>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>

              <div className="shape-wrap">
                <div className="shape-1">
                  <img
                    src="/img/shape/counter-shape.png"
                    alt="Image"
                    id="yds"
                  />
                </div>
                <div className="shape-2">
                  <img
                    src="/img/shape/counter-shape.png"
                    alt="Image"
                    id="yds"
                  />
                </div>
              </div>
            </div>

            <div className="subscribe-area">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-6"
                    data-aos="fade-in"
                    data-aos-delay="200"
                    data-aos-duration="1200"
                  >
                    <h2>Subscribe Now</h2>
                    <p>Get our latest news & update regularly</p>
                  </div>

                  <div className="col-lg-6">
                    <form
                      className="newsletter-form"
                      data-aos="fade-in"
                      data-aos-delay="400"
                      data-aos-duration="1200"
                    >
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        name="EMAIL"
                        required
                      />

                      <button className="default-btn" type="submit">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Footer Contact={Contact} />
          </>
        )}{" "}
      </>
    </div>
  );
}

export default Main;
