import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import { Swiper, SwiperSlide} from "swiper/react";

import axios from "axios";

function About() {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Aboutus, setAboutus] = useState([]);

  const [Aboutus1, setAboutus1] = useState([]);
  const [Aboutus2, setAboutus2] = useState([]);
  const [Aboutus3, setAboutus3] = useState([]);

  const [Contact, setContact] = useState([]);
  const [Professionals, setProfessionals] = useState([]);

  const api = "https://api.gbslifesciences.com/";

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setAboutus(res.data.aboutus[0]);
            setAboutus1(res.data.aboutus[0].points1);
            setAboutus2(res.data.aboutus[0].points2);
            setAboutus3(res.data.aboutus[0].points3);
            setContact(res.data.contactus[0]);
            setProfessionals(res.data.faculties);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />

          <PageBanner
            pageTitle="About"
            homePageUrl="/"
            homePageText="Home"
            activePageText="About"
            imgClass="bg-1"
          />
          <div className="about-area about-page pb-130">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <img src={api + Aboutus.image1} alt="Image" />

                    <div className="shape-1">
                      <img src="/img/about/about-shape-1.png" alt="Image" />
                    </div>

                    <div className="shape-2">
                      <img src="/img/about/about-shape-2.png" alt="Image" />
                    </div>

                    <div className="shape-3">
                      <img src="/img/about/about-shape-3.png" alt="Image" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="about-content">
                    <span className="top-title">About Us</span>
                    <h2>{Aboutus.titile1}</h2>
                    <p>{Aboutus.description2}</p>

                    <ul>
                      {Aboutus1.map((data, i) => {
                        return (
                          <>
                            <li key={i}>
                              <i className="bx bx-check"></i>
                              {data.points1}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about-area two ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-content">
                    <span className="top-title">About GBS</span>
                    <h2>{Aboutus.titile2}</h2>
                    <p>{Aboutus.description2}</p>

                    <ul>
                      {Aboutus2.map((data, i) => {
                        return (
                          <>
                            <li key={i}>
                              <i className="bx bx-check"></i>
                              {data.points2}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="about-img">
                    <img src={api + Aboutus.image2} alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="doctors-area pt-100 pb-70">
            <div className="container">
              <div className="section-title">
                <span className="top-title">Our Doctors</span>
                <h2>Our Specialist Doctors</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                  facilis vel consequatur tempora atque blanditiis
                  exercitationem incidunt, alias corporis quam assumenda dicta,
                  temporibus.
                </p>
              </div>

              <Swiper
                spaceBetween={30}
                navigation={true}
                autoplay={{
                  delay: 6500,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                }}
                // modules={[Navigation, Autoplay]}
                className="doctors-slide"
              >
                {Professionals.map((data, i) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="single-doctor">
                          <img src={api + data.image} alt="Image" />
                          <h3>{data.name}</h3>
                          <span className="top-title">{data.designition}</span>

                          <ul>
                            <li>
                              <a href={data.facebook} target="_blank">
                                <i className="bx bxl-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href={data.twitter} target="_blank">
                                <i className="bx bxl-twitter"></i>
                              </a>
                            </li>

                            <li>
                              <a href={data.instagram} target="_blank">
                                <i className="bx bxl-instagram"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </SwiperSlide>{" "}
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className="about-area four abouts-six">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 pr-0">
                  <img src={api + Aboutus.image3} alt="Image" />
                </div>
                <div className="col-lg-6">
                  <div className="about-content ml-auto">
                    <span className="top-title">About Us</span>

                    <h2>{Aboutus.titile3}</h2>
                    <p>{Aboutus.description3}</p>

                    <ul>
                      {Aboutus3.map((data, i) => {
                        return (
                          <>
                            <li key={i}>
                              <i className="bx bx-check"></i>
                              {data.points3}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="subscribe-area mb-5 mt-5">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6"
                  data-aos="fade-in"
                  data-aos-delay="200"
                  data-aos-duration="1200"
                >
                  <h2>Subscribe Now</h2>
                  <p>Get our latest news & update regularly</p>
                </div>

                <div className="col-lg-6">
                  <form
                    className="newsletter-form"
                    data-aos="fade-in"
                    data-aos-delay="400"
                    data-aos-duration="1200"
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Email"
                      name="EMAIL"
                      required
                    />

                    <button className="default-btn" type="submit">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
           <Footer Contact={Contact} />
        </>
      )}{" "}
    </>
  );
}

export default About;
