import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageBanner from "./PageBanner";
import axios from "axios";

function Blog() {
  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  const [Settings, setSettings] = useState([]);

  const datas = () => {
    axios
      .post("https://api.gbslifesciences.com/v1/gbsapi/web/getallmodules", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setSettings(res.data.settings[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("not getting Data");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <Header Contact={Contact} />
          <PageBanner
            pageTitle="Terms"
            homePageUrl="/"
            homePageText="Home"
            activePageText="Terms"
            imgClass="bg-2"
          />
          <h2 className="text-center pt-4 text-primary">Terms & Conditions</h2>
          <div className="terms-conditions pb-5">
            <div className="container">
              <div className="single-privacy">
                <div
                  className="pt-4"
                  dangerouslySetInnerHTML={{
                    __html: Settings.termsAndCondition,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <h2 className="text-center pt-4 text-primary">Privacy Policy</h2>
          <div className="terms-conditions pb-5">
            <div className="container">
              <div className="single-privacy">
                <div
                  className="pt-4"
                  dangerouslySetInnerHTML={{
                    __html: Settings.privacyPolicy,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <h2 className="text-center pt-4 text-primary">Refund Policy</h2>
          <div className="terms-conditions pb-5">
            <div className="container">
              <div className="single-privacy">
                <div
                  className="pt-4"
                  dangerouslySetInnerHTML={{
                    __html: Settings.refundPolicy,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <Footer Contact={Contact} />
        </>
      )}
    </div>
  );
}

export default Blog;
