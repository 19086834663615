import React from "react";
import "./assets/styles/bootstrap.min.css";
import "./assets/styles/animate.css";
import "./assets/styles/meanmenu.css";
import "./assets/styles/style.css";
import "./assets/styles/responsive.css";
import "./assets/styles/style.css.map";
import "swiper/css";
import "swiper/css/bundle";
import Main from "./Components/Main";
import About from "./Components/About";
import { Routes, Route } from "react-router-dom";
import Doctors from "./Components/Doctors";
import Blog from "./Components/Blog";
import Contact from "./Components/Contact";
import Services from "./Components/Services";
import Products from "./Components/Products";
import ProductsDetails from "./Components/ProductsDetails";
import ProductsSubDetails from "./Components/ProductsSubDetails";
import Signin from "./Components/Signin";
import SignUp from "./Components/SignUp";
import Otp from "./Components/Otp";
import MyAccount from "./Components/MyAccount";
import CheckOut from "./Components/CheckOut";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/Signin" element={<Signin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/doctors" element={<Doctors />} />
        <Route path="/termsconditions" element={<Blog />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/productsdetails" element={<ProductsDetails />} />
        <Route path="/productssubdetails" element={<ProductsSubDetails />} />
        <Route path="/myAccount" element={<MyAccount />} />
        <Route path="/CheckOut" element={<CheckOut />} />
      </Routes>
    </div>
  );
}

export default App;
