import React, { useState, useEffect } from "react";
import { Link, useRoutes } from "react-router-dom";

function Header(props) {
  // Add active class
  const [currentPath, setCurrentPath] = useState("");

  const [menu, setMenu] = React.useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const api = "https://api.gbslifesciences.com/";

  const userid = sessionStorage.getItem("token");

  const databyidS = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <header className="header-area fixed-top">
      <div className="top-header-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-9 col-sm-6">
              <ul className="header-content-left">
                <li id="yds">
                  <i className="bx bx-time"></i>
                  Mon-Fri {props.Contact.fromTime} - {props.Contact.toTime}
                </li>
                <li id="yds">
                  <a href="tel:+900000000">
                    <i className="bx bx-phone-call"></i>
                    Call Us: +91 {props.Contact.contactNumber1}
                  </a>
                </li>
                <li id="yds">
                  <a href="mailto:hello@info.com">
                    <i className="bx bxs-paper-plane"></i>
                    Email: {props.Contact.email1}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-3 col-sm-6">
              <ul className="header-content-right">
                <li>
                  <a href={props.Contact.facebook} target="_blank">
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href={props.Contact.twitter} target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>

                <li>
                  <a href={props.Contact.instagram} target="_blank">
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="nav-area">
        <div id="navbar" className="navbar-area">
          <div className="main-nav">
            <nav className="navbar navbar-expand-md navbar-light">
              <div className="container">
                <Link href="/" className="navbar-brand" id="fs">
                  <img
                    src={api + props.Contact.logo}
                    alt="logo"
                    style={{ height: "80px" }}
                  />
                </Link>

                <button
                  onClick={toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <Link
                        to="/"
                        className={`nav-link ${currentPath == "/" && "active"}`}
                      >
                        HOME{" "}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/about"
                        className={`nav-link ${
                          currentPath == "/About" && "active"
                        }`}
                      >
                        ABOUT US
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/products"
                        className={`nav-link ${
                          currentPath == "/Products" && "active"
                        }`}
                      >
                        PRODUCTS
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/doctors"
                        className={`nav-link ${
                          currentPath == "/Doctors" && "active"
                        }`}
                      >
                        FACILITY
                      </Link>
                    </li>

                

                    <li className="nav-item">
                      <Link
                        to="/contact"
                        className={`nav-link ${
                          currentPath == "/Contact" && "active"
                        }`}
                      >
                        CONTACT US
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="others-option" id="yds">
                  <div className="subscribe">
                    {userid === null ? (
                      <>
                        {" "}
                        <Link to="/Signin" className="default-btn">
                          Log In
                        </Link>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Link
                          onClick={() => {
                            databyidS();
                          }}
                          className="default-btn"
                        >
                          Log Out
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
